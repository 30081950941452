<template>
    <div style="background-color: white" class="print-inbound-po">
        <div id="pdf-content" style="padding: 20px;">
            <div id="header" style="margin-top: 30px;">
                <table width="100%" class="vs-table vs-table--tbody-table" style="font-size: 1.5em; line-height: 0.9;">
                    <tr>
                        <td width="70%" class="td vs-table--td">{{this.data.company?this.data.company.Name:""}}</td>
                        <td width="30%" class="td vs-table--td">PO Date: {{formatDate(this.data.date)}}</td>
                    </tr>
                    <tr>
                        <td width="70%" class="td vs-table--td">NPWP : {{this.data.company?this.data.company.Npwp:""}}</td>
                        <td width="30%" class="td vs-table--td">Print: {{this.data.count_print}}</td>
                    </tr>
                    <tr>
                        <td width="50%" class="td vs-table--td">NPKP : {{this.data.company?this.data.company.Npwp:""}}</td>
                    </tr>
                    <tr>
                        <td width="50%" class="td vs-table--td">{{this.data.company?this.data.company.Address:""}}</td>
                    </tr>
                    <tr>
                        <td width="50%" class="td vs-table--td">Telp.No : {{this.data.company?this.data.company.Phone:""}}</td>
                    </tr>
                    <tr>
                        <td width="50%" class="td vs-table--td">Fax.No : {{this.data.company?this.data.company.Fax:""}}</td>
                    </tr>
                </table>
                <div style="text-align: center; margin: 5px 0 15px 0; font-size: 1.5em;">
                    <p style="margin: 0;"><b>PURCHASE ORDER</b></p>
                    <p style="margin: 0;"><b>NO : {{ this.data.code }}</b></p>
                    <p style="margin: 0;" v-if="data.status=='4'"><b>DRAFT PO</b></p>
                </div>
            </div>
            <table width="100%" class="vs-table vs-table--tbody-table" style="margin-bottom: 15px; font-size: 1.5em; line-height: 0.9;">
                <tr>
                    <td width="50%" class="td vs-table--td">
                        Deliver To : <br>
                        {{this.data.warehouse.code}}-{{this.data.warehouse.name}} <br>
                        {{this.data.warehouse.city}} <br>
                        {{this.data.warehouse.address}}
                    </td>
                    <td width="50%" class="td vs-table--td">
                        Order To : <br>
                        {{this.data.supplier.name}} <br>
                        {{this.data.supplier.address}} <br>
                        Phone : {{this.data.supplier.phone}} <br>
                        Contact Person : {{this.data.supplier.contact_name}}
                    </td>
                </tr>
            </table>
            <table width="100%" class="vs-table vs-table--tbody-table" style="font-size: 1.5em;">
                <tr>
                    <td class="td vs-table--td" style="line-height: 1;">
                        Currency {{ ': ' + this.data.currency_code }}
                    </td>
                </tr>
                <tr>
                    <td class="td vs-table--td" style="line-height: 1;">
                        Term of Payment {{ ': ' + this.data.payment_term.name }}
                    </td>
                </tr>
                <tr>
                    <td class="td vs-table--td" style="line-height: 1;">
                        Delivery Term {{ ': -' }}
                    </td>
                </tr>
                <tr>
                    <td class="td vs-table--td" style="line-height: 1;">
                        Delivery Date {{ ': ' + formatDate(this.data.eta) }}
                    </td>
                </tr>
            </table>
            <div style="margin: 30px 0 15px 0; font-size: 1.5em; line-height: 0.9;">
                Please forward goods to the address indicated above and with price in accordance to your offer. When dispatching goods please indicate our Purchase Order Number. For Payment request, please submit official receipt and invoice to Account Payable
            </div>
            
            <table v-if="this.data.purchase_detail" width="100%" id="table-line" class="vs-table vs-table--tbody-table print" style="font-size: 1.3em;">
                <thead class="vs-table--thead print-head">                
                    <tr>
                        <th width="5%">No</th>
                        <th width="40%">Material Description</th>
                        <th width="10%">Qty UoM</th>
                        <th width="13%">Unit Price</th>
                        <th width="12%">Total Price</th>
                        <th width="10%">Disc (%)</th>
                        <th width="10%" class="text-right">Disc Amt</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in this.data.purchase_detail" :class="{ 'page-break': item.needsPageBreak }" :key="index" style="padding-bottom: 5px; margin-bottom: 10px;">
                        <td class="td vs-table--td">
                            {{index+1}}
                        </td>                    
                        <td class="td vs-table--td">
                            <div>{{ item.sku_code }}</div>
                            <div>{{ item.item_name }}</div>
                        </td>
                        <td class="td vs-table--td">
                            {{ formatLocalNumber(item.quantity) }} {{ item.item_unit }}
                        </td>
                        <td class="td vs-table--td">
                            {{ zero ? 0 : formatLocalNumber(item.price) }} / 1 {{ item.item_unit }}
                        </td>
                        <td class="td vs-table--td">
                            {{ zero ? 0 : formatLocalNumber(item.subtotal) }}
                        </td>
                        <td class="td vs-table--td">
                            {{ zero ? 0 : item.percent_discount }}
                            <!-- {{ zero ? 0 : formatNumDec((item.discount/item.subtotal)*100) }} -->
                        </td>
                        <td class="td vs-table--td text-right">
                            {{ zero ? 0 : formatLocalNumber(item.amount_discount) }}
                        </td>
                    </tr>                
                    <tr>
                        <td colspan="4" style="color: transparent;">-</td>
                        <td colspan="2"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="4" style="color: transparent;">-</td>
                        <td colspan="2"></td>
                        <td></td>
                    </tr>                
                </tbody>
            </table>

            <table width="100%" class="vs-table vs-table--tbody-table total" style="font-size: 1.3em;">
                <tr>
                    <td colspan="4"></td>
                    <td colspan="2" width="15%">Gross Price</td>
                    <td width="15%" class="text-right" style="padding-right: 10px;">{{ formatLocalNumber(this.data.item_price_list) }}</td>
                </tr>
                <tr>
                    <td colspan="4"></td>
                    <td colspan="2" width="15%">Discount (%)</td>
                    <td width="15%" class="text-right" style="padding-right: 10px;">{{ zero ? 0 :formatLocalNumber(this.data.total_disc_percent) }}</td>
                </tr>
                <tr>
                    <td colspan="4"></td>
                    <td colspan="2" width="15%">Discount (Amount)</td>
                    <td width="15%" class="text-right" style="padding-right: 10px;">{{ zero ? 0 :formatLocalNumber(this.data.total_disc_amount) }}</td>
                </tr>
                <tr>
                    <td colspan="4"></td>
                    <td colspan="2" width="15%">Total Net Price</td>
                    <td width="15%" class="text-right" style="padding-right: 10px;">{{ formatLocalNumber(this.data.item_total_purchase) }}</td>
                </tr>
                <tr>
                    <td colspan="4"></td>
                    <td colspan="2" width="15%">Total PPN</td>
                    <td width="15%" class="text-right" style="padding-right: 10px;">{{ zero ? 0 :formatLocalNumber(this.data.item_total_tax) }}</td>
                </tr>                
                <tr>
                    <td colspan="4"></td>
                    <td colspan="2" width="15%">Total Price with PPN</td>
                    <td width="15%" class="text-right" style="padding-right: 10px;">{{ zero ? 0 : formatLocalNumber(this.data.final_total) }}</td>
                </tr>
            </table>

            <br>
            <div class="remark" style="padding-top: 30px;">
                <div style="font-size: 1.5em; line-height: 0.9;">
                    <p style="font-weight: bold;">Amount : {{ this.terbilang }}</p><br>
                    <p>Remarks : <br></p>
                    <textarea class="auto-resize" 
                        readonly
                        ref="textarea"
                        :value="this.data.notes" 
                        @input="autoResize"
                        :style="{ width:'40%', height: textareaHeight + 'px', border: 'none', resize: 'none', overflow: 'hidden' }"></textarea>
                </div>
                
        
                <table width="100%" class="vs-table vs-table--tbody-table" style="margin-top: 50px;">
                    <tr>
                        <td width="50%" class="td vs-table--td" style="padding: 5px;text-align:center; font-size: 1.5em;">Ordered By</td>
                        <td width="50%" class="td vs-table--td" style="padding: 5px;text-align:center; font-size: 1.5em">Acknowledged By</td>
                    </tr>
                    <tr>
                        <td width="50%" class="td vs-table--td" style="padding: 3em;text-align:center"></td>
                        <td width="50%" class="td vs-table--td" style="padding: 3em;text-align:center"></td>
                    </tr>
                    <tr>
                        <td width="50%" class="td vs-table--td" style="padding: 5px;text-align:center;font-size: 1.5em">(...................................................)</td>
                        <td width="50%" class="td vs-table--td" style="padding: 5px;text-align:center;font-size: 1.5em">(...................................................)</td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- <div v-if="data.status=='4'" style="text-align: center;font-size: large;">DRAFT PO</div> -->
        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button class="mr-2" color="primary" @click="handlePrint()">Print</vs-button>
            <vs-button class="mr-2" color="danger" type="border" @click="handleClose()">Close</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import numberToWords from "number-to-words";

export default {
    components: {
    },
    data() {
        return {
            zero: false,
            data: null,
            id: null,
            print: false,
            readonly: true,
            code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            file: null,
            fileUrl: '',
            terbilang: "",
            textareaHeight: 'auto'
        }
    },

    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()
        this.print = this.$route.query.print
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
            setTimeout(() => {
                this.autoResize()
            }, 2000)
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.purchaseOrder.baseUrlPath}/${this.id}?print=true`)
                .then((resp) => {
                    if (resp.code == 200) {
                        const data = resp.data                        
                        this.zero = (data.purchase_type.name == "Bonus" || data.purchase_type.name == "Merchandise")

                        var result = data.code
                        const noPo = data.code.slice(-3)
                        if (noPo.includes("-")) {
                            result = data.code.slice(0, -3)
                        }

                        data.code = result
                        data.total_charge = 0;
                        data.total_charge_tax = 0;
                        data.total_charge_grand = 0;
                        if (data.purchase_charges) {
                            data.purchase_charges.forEach((v, i) => {
                                data.purchase_charges[i].sub_total = Number(v.amount) + Number(v.tax_amount);
                                data.total_charge += Number(v.amount);
                                data.total_charge_tax += Number(v.tax_amount);
                                data.total_charge_grand += Number(v.amount) + Number(v.tax_amount);
                            });
                        }

                        data.item_price_list = 0;
                        data.item_total_discount = 0;
                        data.item_total_purchase = 0;
                        data.item_total_tax = 0;
                        data.item_total_grand = 0;
                        data.discount_percent = 0;
                        data.count_print++
                        data.total_disc_amount = 0
                        data.total_disc_percent = 0

                        // let extended_purchase_detail = this.duplicateArray(data.purchase_detail, 100)
                        // data.purchase_detail = extended_purchase_detail

                        if (data.purchase_detail) {
                            data.purchase_detail.forEach((v, i) => {
                                data.purchase_detail[i].percent_discount = 0
                                data.purchase_detail[i].amount_discount = 0
                                if (this.zero) {
                                    v.subtotal = 0
                                }
                                data.purchase_detail[i].total = Number(v.net) + Number(v.tax);
                                data.item_price_list += Number(v.subtotal);

                                data.item_total_discount += Number(v.discount)
                                data.item_total_tax += Number(v.tax)

                                v.purchase_discount.forEach((v2, i2) => {
                                    if (v2.discount_model == "%") {
                                        data.purchase_detail[i].percent_discount += v2.discount;
                                        data.total_disc_percent += v2.discount_amount
                                    } else {
                                        data.purchase_detail[i].amount_discount += v2.discount;
                                        data.total_disc_amount += v2.discount_amount
                                    }
                                })
                            });                            
                        }

                        if (!this.zero) {
                            data.item_total_purchase = Number(data.item_price_list) - Number(data.item_total_discount);
                            data.item_total_grand = Number(data.item_total_purchase) + Number(data.item_total_tax);
                            data.discount_percent = (data.item_total_discount / data.item_price_list) * 100
                        }

                        data.final_total = Number(data.item_total_grand)//Number(data.total_charge_grand) + Number(data.item_total_grand)

                        // var numberToWords = require('number-to-words');
                        this.terbilang = numberToWords.toWords(data.final_total).toUpperCase()

                        this.data = data;
                        this.$vs.loading.close();
                        if (this.print == true) {
                            console.log("handle print ", this.print)
                            setTimeout(() => {
                                this.handlePrint()
                            }, 1600);
                        }
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        autoResize() {
            this.$nextTick(() => {
                const textarea = this.$refs.textarea;
                if (textarea) {
                    textarea.style.height = 'auto'; // Reset height to auto
                    textarea.style.height = textarea.scrollHeight + 'px'; // Set to scroll height
                    this.textareaHeight = textarea.scrollHeight;
                } else {
                    console.error('Textarea not found');
                }
            });
        },
        duplicateArray(arr, times) {
            let result = [];
            for (let i = 0; i < times; i++) {
                result = result.concat(arr.map(item => JSON.parse(JSON.stringify(item))));
            }
            return result;
        },
        handlePrint() {
            // var url = `http://wms.uat.zesthub.my.id/purchasing/api/print-po/${this.id}`
            var url = process.env.VUE_APP_WMS_WEB + "purchasing/api/print-po/" + this.id
            window.open(url, '_blank')
            setTimeout(() => {
                this.getData();
            }, 2000);
            // this.$http
            //     .get(`${this.$store.state.purchaseOrder.baseUrlPath}/count-print/${this.id}`)
            //     .then((resp) => {
            //         if (resp.code == 200) {
            //         }                    
            //     });
            // var title = document.title
            // document.title = `${this.data.code}-${this.data.warehouse.name}`            
            // await window.print();
            // document.title = title            
        },
        handleClose() {
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName,
            });
        },
    },
    watch: {
        watch: {
            notes() {
                this.autoResize();
            }
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD.MM.YYYY');
            };
        },
        formatNumDec: () => {
            return (val) => {
                const parsedValue = parseFloat(val);
                const options = parsedValue % 1 === 0 ? {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                } : {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                };

                const formattedValue = parsedValue.toLocaleString("en-US", options);
                const valueWithoutCurrencySymbol = formattedValue.replace(/[^\d,.]/g, '');
                return valueWithoutCurrencySymbol;
            }
        },
        formatLocalNumber: () => {
            return (val) => {
                const formattedValue = parseInt(val).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });
    
                const valueWithoutCurrencySymbol = formattedValue.replace(/[Rp\s]/g, '');
                return valueWithoutCurrencySymbol;
            }
        }
    },
}
</script>

<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 portrait;
}

/* Set the font size to 12px for printing */
@media print {
    @page { 
        margin-top: 0;
        margin-bottom: 0;
    }

    body {
        font-size: 0.7em !important;
        background: none !important;
        color: black !important;
    }    

    * {
        background: none !important;
        font-family: "Times New Roman" !important;
    }

    .page-break {
        page-break-after: always;
    }    

    .print-head {
        display: table-header-group;
    }

    table.print {
        page-break-inside: auto;
    }

    .total {
        page-break-inside: avoid;
    }

    .remark {
        page-break-inside: avoid;
    }    

    .vue-back-to-top {
        display: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        background-color: #fff !important;
        margin: 0 !important;
    }
}

table.print {    
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print th {
    border-top: 1px dashed black !important;
    border-bottom: 1px dashed black !important;
    font-size: 1em !important;
}

table.print td,
table.print th {
    text-align: left;
    padding-left: 8px;
    padding-right: 8px;
}

.print-inbound-po{
    font-family: 'Times New Roman', Times, serif !important;
}

textarea.auto-resize {
    font-family: "Times New Roman" !important;
}

.auto-resize {
  border: none;
  outline: none;
  resize: none;
  overflow: hidden; /* Hide the scrollbar */
}
</style>
